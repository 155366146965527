import Link from "@mui/material/Link";
import { default as Typography } from "@mui/material/Typography";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import Seo from "../components/Seo";
import styled from "../util/styled";

const Root = styled("div")(({ theme }) => ({
  padding: "80px 0",
  margin: "auto",
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "center",
}));

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <Seo title="Page inconnue" />
      <Root>
        <img
          style={{
            width: "100%",
            height: 300,
          }}
          src="/images/innovance-solutions-page-inconnue.svg"
          alt="404 page inconnue"
        />
        <Typography align="center" variant="h2" gutterBottom component="h1">
          Oups....
        </Typography>
        <Typography align="center" variant="body1">
          La page que vous demandez n'existe pas.{" "}
          <Link component={GatsbyLink} to="/" underline="hover">
            Retour à l'accueil
          </Link>
        </Typography>
      </Root>
    </React.Fragment>
  );
};

export default NotFoundPage;
